import React, {useEffect, useState} from "react";
import NoteList from "../component/note/noteList";
import {Container, Col, Row} from 'react-bootstrap';
import { APP_ROOT_TITLE } from "../App";
import { useAppSelector } from "../hook/appHook";
import { Note } from "../@types";

const NoteListContainer:React.FC<any> = () => {
    const {isLoading, notes} = useAppSelector((state) => state.EmployeeState);
    const [allNotes, setAllNotes] = useState<Note[]>([]);
    
    useEffect(() => {

        document.title = `${APP_ROOT_TITLE} - Notes`

        if (JSON.stringify(notes) !== JSON.stringify(allNotes)) {
            setAllNotes(notes);
            return;
        }

    }, [notes, allNotes]);

    return(
        <div>
            <Container fluid className="gx-5">
                <Row className="border-bottom gx-0">
                    <Col>
                        <h3>Notes</h3>
                    </Col>
                </Row>

                {!!allNotes?.length && 
                <Row className="mt-4">
                    <Col>
                        <NoteList notes={allNotes} />
                    </Col>
                </Row>
                }

                {!allNotes?.length &&
                    <Row className="gx-0 mt-4">
                        <Col className="text-center">
                            {isLoading ? 'Collecting notes...' : 'No notes have been created'}
                        </Col>
                    </Row>
                }
            </Container>
        </div>
    )
}
            
export default NoteListContainer;