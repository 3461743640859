import { AppConfig } from "../@types";

const LOCAL_CONFIG:AppConfig = {
    awsBaseUrl : 'https://owgb7tr9xe.execute-api.us-east-2.amazonaws.com/api/',
    b2bBaseApiUrl : 'http://localhost:8080/b2b/api/',
    publicBaseUrl : 'http://localhost:8080/public/v1/',
    userPoolId : 'us-east-2_Kd3fmBDo2',
    clientId : '7bv2tnjgkdooqcruik9p2svik3',
    employeeLoginBaseUrl : "https://authemp.dev.martinbros.com",
    b2bServerUrl : 'http://localhost/'
};

const DEVELOPMENT_CONFIG:AppConfig = {
    awsBaseUrl : 'https://owgb7tr9xe.execute-api.us-east-2.amazonaws.com/api/',
    b2bBaseApiUrl : 'https://api.dev.martinbros.com/b2b/api/',
    publicBaseUrl : 'https://api.dev.martinbros.com/public/v1/',
    userPoolId : 'us-east-2_Kd3fmBDo2',
    clientId : '7bv2tnjgkdooqcruik9p2svik3',
    employeeLoginBaseUrl : "https://authemp.dev.martinbros.com",
    b2bServerUrl : 'https://customer.dev.martinbros.com/'
};

const QA_CONFIG:AppConfig = {
    awsBaseUrl : 'https://u68w56vqse.execute-api.us-east-2.amazonaws.com/api/',
    b2bBaseApiUrl : 'https://api.qa.martinbros.com/b2b/api/',
    publicBaseUrl : 'https://api.qa.martinbros.com/public/v1/',
    userPoolId : 'us-east-2_EeBKTXXMm',
    clientId : '5li1583segm0ut95igdsrngtr',
    employeeLoginBaseUrl : "https://authemp.qa.martinbros.com",
    b2bServerUrl : 'https://customer.qa.martinbros.com/'
};

const PRODUCTION_CONFIG:AppConfig = {
    awsBaseUrl : 'https://lw5wiw0ag4.execute-api.us-east-2.amazonaws.com/api/',
    b2bBaseApiUrl : 'https://api.martinbros.com/b2b/api/',
    publicBaseUrl : 'https://api.martinbros.com/public/v1/',
    userPoolId : 'us-east-2_SlaMIaj6t',
    clientId : '7v1mcamb1jp9kq0t0v1l9duae5',
    employeeLoginBaseUrl : "https://authemp.martinbros.com",
    b2bServerUrl : 'https://customer.martinbros.com/'
};

export function getConfiguration():AppConfig {
    const environment = process.env.REACT_APP_FOOD_SHOW_ENV;
    switch (environment) {
        case 'local' : 
            return {...LOCAL_CONFIG};
        case 'qa' :
            return {...QA_CONFIG};
        case 'production' : 
            return {...PRODUCTION_CONFIG};
        case 'development' : 
        default :
            return {...DEVELOPMENT_CONFIG};
    }
}

export function constructEmployeeLoginUrl(config:AppConfig):string {
    const {employeeLoginBaseUrl, clientId} = config;
    const redirectUrl = buildRedirectUrl();

    return `${employeeLoginBaseUrl}/oauth2/authorize` 
        + `?client_id=${clientId}`
        + '&response_type=code'
        + '&identity_provider=MartinBros' 
        + `&redirect_uri=${redirectUrl}`;
}

export function constructEmployeeLogoutUrl(config:AppConfig):string {
    const {employeeLoginBaseUrl, clientId} = config;
    return `${employeeLoginBaseUrl}/logout` 
        + `?client_id=${clientId}` 
        + `&logout_uri=${constructApplicationRootUrl()}`;
}

function constructApplicationRootUrl():string {
    const {hostname, protocol, port} = window.location;
    const host = /\d+\./.test(hostname)
        ? 'localhost'
        : hostname;

    const portValue = (port && ('80' !== port) && ('443' !== port))
        ? `:${port}`
        : '';

    return `${protocol}//${host}${portValue}`;
}

function buildRedirectUrl():string {
    const {pathname} = window.location;
    
    const path = pathname.endsWith('/') 
        ? pathname.substring(0, pathname.length - 1) 
        : pathname;

    const rootUrl = constructApplicationRootUrl();
    return `${rootUrl}${path}`;
}