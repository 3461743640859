import React from "react";
import { Row } from "react-bootstrap";
import { Presenter } from "../../@types";
import PresenterSummary from "./presenterSummary";

export interface PresenterListProps {
    presenters: Presenter[]
}

const PresenterList:React.FC<PresenterListProps> = ({presenters}) => 

    <Row xs={1} md={2} lg={3} className="g-4">
        {presenters.map(presenter => <PresenterSummary key={presenter.number} presenter={presenter} />)}
    </Row>

export default PresenterList;