export const INITIALIZE_EMPLOYEE = 'INITIALIZE_EMPLOYEE';
export const INITIALIZE_EMPLOYEE_SUCCESS = 'INITIALIZE_EMPLOYEE_SUCCESS';
export const INITIALIZE_EMPLOYEE_FAILURE = 'INITIALIZE_EMPLOYEE_FAILURE';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE';