import { ApiResponseHandler, Presenter } from "../../../@types";
import PresenterApi from "../../../api/presenter/presenterApi";
import { handleApiResponse } from "../../../util/apiUtil";

class PresenterService {
    public async getPresenterList(token:string, handler:ApiResponseHandler<Presenter[]>):Promise<void> {
        const response = await PresenterApi.fetchPresenterList(token);
        handleApiResponse(response, handler);  
    }
}

export default new PresenterService();