import React, {useState} from "react";
import {Alert, Button, Card, Col, Row} from 'react-bootstrap';
import NoteEditor from "./noteEditor";
import ConfirmationModal, { ConfirmationProps } from "../modal/mbModalConfirmation";
import { ApiResponseHandler, Note, Nullable } from "../../@types";
import { MBModalProps } from "../modal/mbModal";
import { useAccessToken } from "../../hook/accessTokenHook";
import { useAppDispatch } from "../../hook/appHook";
import { deleteNote } from "../../state/action/employee/employeeAction";

export type NoteProps = {
    note: Note
};

const ItemNoteCard:React.FC<NoteProps> = (props) => {
    const {note} = props;
    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [isRemoveNoteConfirmation, setIsRemoveNoteConfirmation] = useState(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const token = useAccessToken();
    const dispatch = useAppDispatch();

    const customerIdsClone = note.customerIds?.length ? [...note.customerIds] : [];
    const customerIdsString = customerIdsClone?.length ? customerIdsClone.join(", ") : "";

    const noteEditorModalProps = {
        note: note,
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const removeModalProps:MBModalProps = {
        mbModalProps: {
            actionBtnText:"Remove",
            title:`Remove Item Note`,
            onModalSubmit: () => {
                return new Promise(resolve => {
                    const handler:ApiResponseHandler<any> = {
                        onSuccess: () => resolve(true),
                        onError: (error:string) => {
                            resolve(false);
                        }
                    }
                    dispatch(deleteNote(token, note.timestamp, handler));
                });
            },
            size: "sm"
        }
    }

    const removeNoteModalProps:ConfirmationProps = {
        message:`Remove this item note?`,
        mbModalProps: removeModalProps.mbModalProps,
        show: true,
        onHide: () => setIsRemoveNoteConfirmation(false)
    }

    return (
        <div className="position-relative border-bottom note-row">
            <Card className="border-0 ">
                <Card.Body className="px-0">
                    <Row className="mb-1 align-items-center">
                        <Col>
                            {!!error &&
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>
                            }
                            <Card.Text as="div" className="mb-0">
                                {!!customerIdsString?.length &&
                                    <div className="mb-inline-label-group">
                                        <label>Customers</label>
                                        <div>
                                            {customerIdsString}
                                        </div>
                                    </div>
                                }
                                <div className="mb-inline-label-group align-items-start">
                                    <label>Note</label>
                                    <div>{note.noteText}</div>
                                </div>
                            </Card.Text>
                        </Col>
                        <Col xs="12" sm="auto" className="mt-2 mt-md-0 d-sm-flex d-grid">
                            <Button className="mb-button me-sm-2 mb-2 mb-sm-0" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Edit</Button>
                            <Button className="mb-button" variant="" size="sm" onClick={() => setIsRemoveNoteConfirmation(true)}>Remove</Button>
                            
                            {isShowNoteEditor &&<NoteEditor {...noteEditorModalProps}  />}
                            {isRemoveNoteConfirmation && <ConfirmationModal {...removeNoteModalProps} /> }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
};

export default ItemNoteCard;