import React, { useState } from "react";
import Image from '../component/app/image'
import {Navbar, Container, Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import * as AppRoutes from '../config/routes';
import { useAppSelector } from "../hook/appHook";

const Header = () => {
    const { appDisplayName } = useAppSelector((state) => state.AppState);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    }

    const onNavClick = () => {
        setIsNavOpen(false);
    }
    
    return(
        <header className="sticky-top">
            <Navbar bg="dark" variant="dark" expand="md" expanded={isNavOpen}>
            <Container fluid>
                <Navbar.Brand>
                    <Link to="/">
                        <Image src="https://cdn.martinbros.com/content/m_badge_logo_300.png" width="60" alt="MB Logo" className="me-2" />
                        {appDisplayName}
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleNav} />
                <Navbar.Collapse id="navbar-nav">
                <Nav className="ms-auto">
                    <Link to={AppRoutes.ROUTE_PRESENTER_LIST} onClick={onNavClick} className="nav-link">Presenters</Link>
                    <Link to={AppRoutes.ROUTE_ITEM_LIST} onClick={onNavClick} className="nav-link">Items</Link>
                    <Link to={AppRoutes.ROUTE_NOTE_LIST} onClick={onNavClick} className="nav-link">Notes</Link>
                    <Link to={AppRoutes.ROUTE_LOGOUT} onClick={onNavClick} className="nav-link">Logout</Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </header>
    )
}

export default Header;