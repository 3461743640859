import React, {useEffect, useMemo, useState} from "react";
import { ItemListItem } from "../@types";
import {Container, Form, Col, Row} from 'react-bootstrap';
import { useAppSelector } from "../hook/appHook";
import ItemList from "../component/items/itemList";
import Loader from "../component/app/loader";
import { APP_ROOT_TITLE } from "../App";

const ItemListContainer:React.FC<any> = () => {
    const [allItems, setAllItems] = useState<ItemListItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<ItemListItem[]>([]);
    const [isStateLoading, setIsStateLoading] = useState(false);
    const {isLoading, presenters} = useAppSelector((state) => state.PresenterState);

    useEffect(() => {
        document.title = `${APP_ROOT_TITLE} - Item Search`
    });

    const memoItems = useMemo(() => {
        const items:ItemListItem[] = [];
        presenters.forEach((presenter) => presenter.items.forEach((item) => {
            const listItem:ItemListItem = {
                item:item,
                presenterNumber: presenter.number,
                presenterName: presenter.name
            };
            items.push(listItem)
        }
        ));
        return items;
    }, [presenters]);

    useEffect(() => {
        if (!!memoItems.length && !allItems.length) {
            setAllItems(memoItems);
            setFilteredItems(memoItems);
            setIsStateLoading(true);
            return;
        }

        setIsStateLoading(isLoading);
    }, [memoItems, allItems, isLoading]);
    
    
    const onSearchChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        const search:string = event.currentTarget.value.trim().toLowerCase();
        if (search.length === 0) {
            setFilteredItems(allItems);
            return;
        }

        if (search.length < 3) {
            return;
        }

        const matchedItems = allItems.filter((item) =>
                (item.item.number.toLowerCase().indexOf(search) > -1) 
                || (item.item.description.toLowerCase().indexOf(search) > -1));

        setFilteredItems(matchedItems);        
    }

    return (
        <Container fluid className="gx-5">
            <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                <Col>
                    <h2>Items</h2>
                </Col>
                <Col md="auto" className="justify-content-end">
                    <Form.Control type="text" onChange={onSearchChanged} placeholder="Search items..." />
                </Col>
            </Row>
            {isStateLoading && <Loader />}
            {!!filteredItems.length && <ItemList items={filteredItems} /> }
            
            {!filteredItems.length &&
                <Row className="mt-4">
                    <Col className="text-center">
                        {!!isStateLoading ? 'Preparing Items...' : 'No Items found'}
                    </Col>
                </Row>
            }
        </Container>
    );
}

export default ItemListContainer;