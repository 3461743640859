import { ApiResponse } from "../../@types";
import Api from "../api";

const USER_BASE = 'users/'

class B2BUserApi {
    public async fetchUserCustomers(token:string):Promise<ApiResponse> {
        return Api.performB2BGETRequest(`${USER_BASE}account/500?isBillToOnlyIncluded=true`, token);
    }
}

export default new B2BUserApi();