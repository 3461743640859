import React from "react";
import { Accordion } from "react-bootstrap";
import { Note } from "../../@types";
import PresenterNotes from "./presenterNotes";

export interface NoteProps {
    notes: Note[]
}

const NoteList:React.FC<NoteProps> = ({notes}) => {

    const presenterNumbers:number[] = [];

    notes.forEach((note) => {
        if (!presenterNumbers.includes(note.presenterNumber)) {
            presenterNumbers.push(note.presenterNumber);
        }  
    });

    return(
        <>
            {presenterNumbers &&
                <>
                <Accordion alwaysOpen={false}>
                    {presenterNumbers.map((presenterNumber:number) =>
                        <PresenterNotes key={presenterNumber} notes={notes} presenterNumber={presenterNumber} />
                    )}
                    </Accordion>
                </>
            }
        </>
    )
}

export default NoteList;