import React from "react";
import { PresenterContact } from "../../@types";

export type ContactProps = {
    contact: PresenterContact
};

const Contact:React.FC<ContactProps> = ({contact}) => {
    const mailTo = `mailto:${contact.email}`;
    const phoneNumber = `tel:${contact.phoneNumber}`;

    return(
        <div className="d-md-flex flex-wrap presenter-contact">
            <div className="presenter-contact-meta">{contact.name}</div>
            <div className="presenter-contact-meta"><a href={mailTo}>{contact.email}</a> <span className="ps-1">(Broker Manager)</span></div>
            <div><a href={phoneNumber}>{contact.phoneNumber}</a></div>
        </div>
    )
};

export default Contact;