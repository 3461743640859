import { ApiTokenResponse, Nullable } from '../../@types';
import UserDao from '../../persistence/storage/userDao';

class UserService {
    public getLastUserTokens() {
        return UserDao.retrieveLastUsedTokens();
    }

    public saveUserTokens(tokens:Nullable<ApiTokenResponse>) {
        if (tokens != null) {
            UserDao.persistUserTokens(tokens);
        }
    }

    public removeUserTokens():void {
        UserDao.removeUserTokens();
    }
}

export default new UserService();