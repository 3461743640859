import React, {useEffect, useState} from "react";
import ItemList from "../items/itemList";
import { Item, ItemListItem } from "../../@types";
import Image from '../app/image';
import PresenterContacts from "./presenterContacts";
import NoteEditor from "../note/noteEditor";
import {Button, Container, Col, Row, Form} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hook/appHook";
import { EMPTY_NOTE } from "../../service/app/employee/employeeService";
import PresenterDownloads from "./presenterDownloadsList";
import Loader from "../app/loader";

const PresenterDetail = () => {
    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [presenterItems, setPresenterItems] = useState<Item[]>([]);
    const [filteredItems, setFilteredItems] = useState<Item[]>([]);
    const [isStateLoading, setIsStateLoading] = useState(false);

    const id = parseInt(useParams().id ?? '0');
    const {isLoading, presenters} = useAppSelector((state) => state.PresenterState);
    const presenter = presenters.find(presenter => presenter.number === id);

    useEffect(() => {

        if (!isLoading && !presenterItems.length && presenter) {
            setPresenterItems(presenter.items);
            setFilteredItems(presenter.items);
            setIsStateLoading(true);
            return;
        }

        setIsStateLoading(isLoading);
    }, [presenterItems, presenter, isLoading]);

    if (!presenter) {
        return (
            <Container fluid>
                <Row>
                    <Col className="text-center">Presenter could not be found</Col>
                </Row>
            </Container>
        );
    }

    const itemListItems:ItemListItem[] = [];
    presenter && filteredItems.forEach((item) => {
        const itemListItem:ItemListItem = {
            item: item,
            presenterNumber: presenter.number,
            presenterName: presenter.name
        };

        itemListItems.push(itemListItem);
    });

    const noteEditorModalProps = {
        note: {
            ...EMPTY_NOTE,
            presenterNumber: presenter.number,
            presenterName: presenter.name
        },
        title: "Presenter Note",
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const onSearchChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        const search:string = event.currentTarget.value.trim().toLowerCase();
        if (search.length === 0) {
            setFilteredItems(presenterItems);
            return;
        }

        if (search.length < 3) {
            return;
        }

        const matchedItems = presenterItems.filter((item) =>
                (item.number.toLowerCase().indexOf(search) > -1) 
                || (item.description.toLowerCase().indexOf(search) > -1));

        setFilteredItems(matchedItems);        
    }

    return (
            <div>
                <Container fluid className="gx-5 mb-4">
                    <Row className="align-items-center gl-0">
                        <Col sm="auto" className="mr-3">
                            <Image 
                            src={presenter.logoUrl} 
                            alt={presenter.name} />
                        </Col>
                        <Col className="mb-2 mb-sm-0">
                            <h2 className="text-start mb-2">{presenter.name}</h2>
                            <PresenterContacts contacts={presenter.contacts} />
                            <PresenterDownloads downloads={presenter.downloads} />
                        </Col>
                        {!!presenter?.boothAssignment.length &&
                        <Col sm={true} className="mb-2 mb-sm-0">
                            <div className="mb-inline-label-group align-self-end">
                                <label>Booth</label>
                                <div>{presenter.boothAssignment}</div>
                            </div>
                        </Col>
                        }
                        <Col xs="auto">
                            <Button className="mb-button mb-2" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Presenter Note</Button>
                            {isShowNoteEditor &&<NoteEditor {...noteEditorModalProps}  />}
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="gx-5">
                    <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                        <Col>
                            <h2>Presenter Items</h2>
                        </Col>
                        <Col md="auto" className="justify-content-end">
                            <Form.Control type="text" onChange={onSearchChanged} placeholder="Search items..." />
                        </Col>
                    </Row>

                    {isStateLoading && <Loader />}

                    {!!itemListItems.length && <ItemList items={itemListItems} /> }
                    
                    {!itemListItems.length &&
                        <Row className="mt-4">
                            <Col className="text-center">
                                {!!isStateLoading ? 'Preparing Items...' : 'No Items found'}
                            </Col>
                        </Row>
                    }

                </Container>
            </div>
        
    );
}

export default PresenterDetail;