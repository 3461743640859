import { ApiResponseHandler, AppThunk, Nullable, Employee, Note } from "../../../@types";
import EmployeeService from "../../../service/app/employee/employeeService";
import { INITIALIZE_EMPLOYEE, INITIALIZE_EMPLOYEE_SUCCESS, INITIALIZE_EMPLOYEE_FAILURE, DELETE_NOTE, DELETE_NOTE_SUCCESS, DELETE_NOTE_FAILURE, ADD_NOTE, UPDATE_NOTE, ADD_NOTE_SUCCESS, ADD_NOTE_FAILURE, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE } from "./employeeActionType";

export function loadEmployee(token:Nullable<string>):AppThunk {
    return (dispatch) => {
        dispatch({
            type: INITIALIZE_EMPLOYEE
        });

        if (!token) {
            dispatch({type: INITIALIZE_EMPLOYEE_FAILURE});
        }
        else {
            const handler:ApiResponseHandler<Employee> = {

                onSuccess : (employee) => {
                    dispatch({
                        type: INITIALIZE_EMPLOYEE_SUCCESS,
                        payload: employee
                    });
                },
                onError : (error:string) => {
                    dispatch({
                        type: INITIALIZE_EMPLOYEE_FAILURE
                    });
                }
            }
            EmployeeService.initializeEmployee(token, handler);
        }
    }
}

export function saveNote(token:Nullable<string>, note:Note, callbackHandler:ApiResponseHandler<any>):AppThunk {
    return (dispatch) => {
        if (!token) {
            return;
        }

        let dispatchType = ADD_NOTE;
        let dispatchTypeSuccess = ADD_NOTE_SUCCESS;
        let dispatchTypeFailure = ADD_NOTE_FAILURE;

        if (note.timestamp) {
            dispatchType = UPDATE_NOTE;
            dispatchTypeSuccess = UPDATE_NOTE_SUCCESS;
            dispatchTypeFailure = UPDATE_NOTE_FAILURE;
        }

        dispatch({
            type: dispatchType
        });

        const handler:ApiResponseHandler<any> = {

            onSuccess : (updatedNote) => {
                dispatch({
                    type: dispatchTypeSuccess,
                    payload: updatedNote
                });

                callbackHandler.onSuccess(null);
            },
            onError : (error:string) => {
                dispatch({
                    type: dispatchTypeFailure
                });

                callbackHandler.onError(error);
            }

        }

        EmployeeService.saveNote(token, note, handler);
    }
}

export function deleteNote(token:Nullable<string>, timestamp:string, callbackHandler:ApiResponseHandler<any>):AppThunk {
    return (dispatch) => {
        if (!token) {
            return;
        }

        dispatch({
            type: DELETE_NOTE
        });

        const handler:ApiResponseHandler<any> = {

            onSuccess : () => {
                dispatch({
                    type: DELETE_NOTE_SUCCESS,
                    payload: timestamp
                });

                callbackHandler.onSuccess(null);
            },
            onError : (error:string) => {
                dispatch({
                    type: DELETE_NOTE_FAILURE
                });

                callbackHandler.onError(error);
            }

        }

        EmployeeService.deleteEmployeeNote(token, timestamp, handler);
    }
}