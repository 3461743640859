import { MBAction, PresenterState } from '../../@types';
import {
    FETCH_PRESENTER_LIST,
    FETCH_PRESENTER_LIST_SUCCESS,
    FETCH_PRESENTER_LIST_FAILURE } from '../action/presenter/presenterActionType';

const INITIAL_STATE:PresenterState = {
    isLoading : false,
    presenters : []
};

function updatePresenterState(state:PresenterState = INITIAL_STATE, action:MBAction):PresenterState {
    switch (action.type) {
        case FETCH_PRESENTER_LIST :
            return {
                ...state, 
                isLoading: true
            };

        case FETCH_PRESENTER_LIST_SUCCESS :
            return {
                ...state, 
                isLoading: false, 
                presenters: action.payload
            };

        case FETCH_PRESENTER_LIST_FAILURE :
            return {
                ...state, 
                isLoading: false, 
                presenters: []
            };

        default : 
            return state;
    }
}

export default updatePresenterState;