import { CognitoAccessToken, 
    CognitoIdToken, 
    CognitoRefreshToken, 
    CognitoUser, 
    CognitoUserPool, 
    CognitoUserSession, 
    ICognitoUserData } from "amazon-cognito-identity-js";
import { ApiResponseHandler, ApiTokenResponse, AppThunk, AppUser, CognitoUserTokens, Nullable } from "../../../@types";
import AppService from "../../../service/app/appService";
import { INITIALIZE_APP, 
    INITIALIZE_APP_SUCCESS, 
    USER_LOGOUT_SUCCESS, USER_LOGOUT} from "./appActionType";
import {constructEmployeeLogoutUrl, getConfiguration} from '../../../config/configuration';
import UserService from '../../../service/user/userService';

export function initializeApp(code:Nullable<string>):AppThunk {
    return (dispatch, getState) => {
        const {isLoading, isInitialized} = getState().AppState;

        if (isLoading || isInitialized) {
            return;
        }

        dispatch({
            type: INITIALIZE_APP
        });

        const apiTokens = UserService.getLastUserTokens();
        
        let appUser:Nullable<AppUser> =  (apiTokens != null)
            ? createAppUserFromApiTokens(apiTokens)
            : null;

        if (!!appUser || !code) {
            dispatch({
                type: INITIALIZE_APP_SUCCESS,
                payload : {appUser: appUser, isLoginFailed: false}
            }); 

            return;
        }

        let isLoginFailed = false;
        const handler:ApiResponseHandler<any> = {
            onSuccess : (data:any) => {
                if (!data?.access_token 
                        || !data?.id_token 
                        || !data?.refresh_token) {
                    isLoginFailed = true;
                }
                else {
                    const tokens = data as ApiTokenResponse;
                    appUser = createAppUserFromApiTokens(tokens);
                    UserService.saveUserTokens(tokens);
                }

                dispatch({
                    type: INITIALIZE_APP_SUCCESS,
                    payload: {appUser, isLoginFailed}
                });
            },
            onError : (error:string) => {
                isLoginFailed = true;
                dispatch({
                    type: INITIALIZE_APP_SUCCESS,
                    payload: {appUser, isLoginFailed}
                });
            }
        };

        AppService.exchangeCodeForTokens(code, handler);        
    }
}

export function createCognitoUserFromAppUser(appUser:AppUser, userPoolId:string, clientId:string):Nullable<CognitoUser> {
    return createCognitoUser(userPoolId, clientId, appUser);
}

function createAppUserFromApiTokens(apiTokens:Nullable<ApiTokenResponse>):Nullable<AppUser> {
    const tokens = createUserTokensFromApiTokens(apiTokens);
    if (!tokens) {
        return null;
    }

    const username = tokens.idToken.decodePayload()['cognito:username'];
    return {username, tokens};
}
 
function createUserTokensFromApiTokens(apiTokens:Nullable<ApiTokenResponse>):Nullable<CognitoUserTokens> {
    if (apiTokens == null) {
        return null;
    }

    return {
        idToken : new CognitoIdToken({IdToken : apiTokens.id_token}),
        accessToken : new CognitoAccessToken({AccessToken: apiTokens.access_token}),
        refreshToken : new CognitoRefreshToken({RefreshToken: apiTokens.refresh_token})
    }
}

function createCognitoUser(
        userPoolId:string,
        clientId:string,
        appUser:Nullable<AppUser>):Nullable<CognitoUser> {
    if (appUser == null) {
        return null;
    }

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolId, 
        ClientId : clientId
    });

    const userData:ICognitoUserData = {
        Username : appUser.username,
        Pool : userPool
    };
        
    const cognitoTokens = appUser.tokens;
    const user = new CognitoUser(userData);
    const userSession = new CognitoUserSession({
        AccessToken : cognitoTokens.accessToken,
        IdToken : cognitoTokens.idToken,
        RefreshToken : cognitoTokens.refreshToken
    });

    user.setSignInUserSession(userSession);
    return user;
}

export function logout():AppThunk {
    return (dispatch, getState) => {
        const {isLoading, appUser} = getState().AppState;

        if (isLoading || !appUser) {
            return;
        }
        
        dispatch({type: USER_LOGOUT});

        const config = getConfiguration();
        const {userPoolId, clientId} = config;
        const cognitoUser = createCognitoUserFromAppUser(appUser, userPoolId, clientId);

        const dispatchEndSession = () => {
            UserService.removeUserTokens();
            dispatch({type: USER_LOGOUT_SUCCESS});
            window.location.href = constructEmployeeLogoutUrl(config);
        };

        if (!cognitoUser) {
            dispatchEndSession();
            return;
        }

        cognitoUser.signOut();
        dispatchEndSession();
    }
}