import { ApiResponse } from "../../@types";
import Api from "../api";

const CUSTOMER_BASE = 'customer/';

class CustomerApi {
    public async searchCustomerAccounts(token:string, search:string):Promise<ApiResponse> {
        const url = `${CUSTOMER_BASE}find-account?term=${search}`
            + '&isInactiveCustomerIncluded=true';

        const response = await Api.performB2BGETRequest(url, token);
        return response;
    }
}

export default new CustomerApi();