import React, { useEffect, useState } from "react";
import Loader from "../component/app/loader";
import { useAppSelector } from "../hook/appHook";
import {Container, Col, Row, Form} from 'react-bootstrap';
import PresenterList from "../component/presenter/presenterList";
import { Presenter } from "../@types";
import { APP_ROOT_TITLE } from "../App";

const PresenterListContainer:React.FC<any> = () => {
    const {isLoading, presenters} = useAppSelector((state) => state.PresenterState);
    const [filteredPresenters, setFilteredPresenters] = useState<Presenter[]>([]);
    const [allPresenters, setAllPresenters] = useState<Presenter[]>([]);

    useEffect(() => {

        document.title = `${APP_ROOT_TITLE} - Presenters`

        if (!!presenters.length && !allPresenters.length) {
            setAllPresenters(presenters);
            setFilteredPresenters(presenters);
            return;
        }

    }, [presenters, allPresenters]);

    const onSearchChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
        const search:string = event.currentTarget.value.trim().toLowerCase();
        if (search.length === 0) {
            setFilteredPresenters(allPresenters);
            return;
        }

        if (search.length < 1) {
            return;
        }

        const matchedPresenters = allPresenters.filter((presenter) => 
        (presenter.name.toLowerCase().indexOf(search) > -1)
        || (presenter.boothAssignment.toLowerCase().indexOf(search) > -1));

        setFilteredPresenters(matchedPresenters);        
    }

    return (
        <div>
            {isLoading && <Loader />}
            <Container fluid className="gx-5">
                <Row className="border-bottom gx-0 pb-2 pb-md-0 mb-3">
                    <Col>
                        <h2>Presenters</h2>
                    </Col>
                    <Col md="auto" className="justify-content-end">
                        <Form.Control type="text" onChange={onSearchChanged} placeholder="Search presenters..." />
                    </Col>
                </Row>

                {!!filteredPresenters.length && <PresenterList presenters={filteredPresenters} />}
            </Container>
        </div>
    )
}

export default PresenterListContainer;