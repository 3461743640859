import React from "react";
import { Note } from "../../@types";
import PresenterNoteCard from "./presenterNoteCard";
import { Row, Col } from "react-bootstrap";

export interface NoteListProps {
    notes: Note[],
    presenterNumber: number
}

const PresenterOnlyNotes:React.FC<NoteListProps> = (props) => {
    const {notes, presenterNumber} = props;
    const presenterNotes = notes.filter(note => note.presenterNumber === presenterNumber);

    return (
        <> 
            <Row className="m-0 g-0 align-items-center flex-grow-1">
                <Col>
                    <div className="item-title">Presenter Notes</div>
                </Col>
                <Col sm="auto" className="ms-auto">
                {presenterNotes.length} {presenterNotes.length > 1 ? 'Notes' : 'Note'} 
                </Col>
            </Row>
            <div className="notes-list">
                {presenterNotes.map((note) => 
                    <PresenterNoteCard key={note.timestamp} note={note} />
                )}
            </div>
        </>
    )
}

export default PresenterOnlyNotes;