import { AppState, AppUser, CognitoUserTokens, MBAction, Nullable } from '../../@types';
import { 
    ACCESS_TOKEN_REFRESH,
    INITIALIZE_APP,
    INITIALIZE_APP_SUCCESS,
    USER_LOGOUT,
    USER_LOGOUT_SUCCESS,
    LOAD_APP_NAME_SUCCESS} from '../action/appAction/appActionType';

const INITIAL_STATE:AppState = {
    isLoading: false,
    isLoginFailed : false,
    isInitialized : false,
    appUser : null,
    appDisplayName: ''
};

const reducer = (state:AppState = INITIAL_STATE, action:MBAction):AppState => {
    switch(action.type) {
        case INITIALIZE_APP : 
            return {
                ...state,
                isLoading : true
            };

        case INITIALIZE_APP_SUCCESS :
            return {
                ...state, 
                isLoading : false,
                isInitialized : true,
                appUser : (action.payload.appUser ?? null),
                isLoginFailed : !!action.payload.isLoginFailed
            };

        case ACCESS_TOKEN_REFRESH : 
            let user:Nullable<AppUser> = null;
            if (state.appUser) {
                const tokens:CognitoUserTokens = {
                    ...state.appUser?.tokens,
                    accessToken : action.payload
                };

                user = {
                    ...state.appUser,
                    tokens : tokens
                };
            }

            return {
                ...state,
                appUser : user
            }

        case USER_LOGOUT : 
            return {
                ...state,
                isLoading : true
            };

        case USER_LOGOUT_SUCCESS : 
            return {
                ...state,
                isLoading: false,
                appUser : null
            };

        case LOAD_APP_NAME_SUCCESS :
            return {
                ...state,
                appDisplayName: action.payload
            };
        
        default : 
            return state
    }
}

export default reducer;