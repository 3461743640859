import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as AppRoutes from '../config/routes';
import Header from '../component/header';
import Footer from '../component/footer';
import PresenterListContainer from './presenterListContainer';
import ItemListContainer from './itemListContainer';
import NoteListContainer from './noteListContainer';
import PresenterDetail from '../component/presenter/presenterDetail';
import Logout from './logout';
import {loadPresenterList} from '../state/action/presenter/presenterAction';
import { loadEmployee } from '../state/action/employee/employeeAction';
import { useAppDispatch } from '../hook/appHook';
import { useAccessToken } from '../hook/accessTokenHook';
import { APP_ROOT_TITLE } from '../App';

const Home = () => {
    const dispatch = useAppDispatch();
    const token = useAccessToken();
    
    useEffect(() => {
        dispatch(loadPresenterList(token));
        dispatch(loadEmployee(token));
        document.title = `${APP_ROOT_TITLE}`
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Header />
            <div className="container-body">
                <Routes>
                    <Route path={AppRoutes.ROUTE_HOME} element={<PresenterListContainer />} />
                    <Route path={AppRoutes.ROUTE_PRESENTER_LIST} element={<PresenterListContainer />} />
                    <Route path={AppRoutes.ROUTE_ITEM_LIST} element={<ItemListContainer />} />
                    <Route path={AppRoutes.ROUTE_NOTE_LIST} element={<NoteListContainer />} />
                    <Route path={AppRoutes.ROUTE_PRESENTER_DETAIL} element={<PresenterDetail />} />
                    <Route path={AppRoutes.ROUTE_LOGOUT} element={<Logout />} />
                </Routes>
                <Footer />
            </div>
            
        </BrowserRouter>
    );
}

export default Home;