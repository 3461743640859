import { ApiResponse, ApiResponseHandler, Employee, Note, Nullable } from "../../../@types";
import { REQUIRED_TOKEN_MESSAGE } from "../../../api/api";
import EmployeeApi from "../../../api/employee/employeeApi";
import { handleApiResponse } from "../../../util/apiUtil";

export const EMPTY_NOTE:Note = {
    timestamp: "",
    presenterNumber: 0,
    presenterName: ""
}

class EmployeeService {
    public async initializeEmployee(token:string, handler:ApiResponseHandler<Employee>):Promise<void> {
        const response = await EmployeeApi.initializeEmployee(token);
        handleApiResponse(response, handler);  
    }

    public async saveNote(token:Nullable<string>, note:Note, handler:ApiResponseHandler<Note>):Promise<void> {
        
        if (!token) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const validationError = this.validateNote(note);
        if (validationError) {
            const error:ApiResponse = {data: null, error: validationError};
            handleApiResponse(error, handler);
            return;
        }

        let response = null;
        if (note.timestamp) {
            response = await EmployeeApi.updateEmployeeNote(token, note);
        } else {
            response = await EmployeeApi.createEmployeeNote(token, note);
        }
         
        handleApiResponse(response, handler);  
    }

    public async createFlaggedNote(token:Nullable<string>, itemNumber:string, handler:ApiResponseHandler<Note>):Promise<void> {
        
        if (!token || !itemNumber) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await EmployeeApi.createEmployeeFlaggedItem(token, itemNumber);
         
        handleApiResponse(response, handler);  
    }

    public async deleteEmployeeNote(token:Nullable<string>, timestamp:string, handler:ApiResponseHandler<any>):Promise<void> {

        if (!token) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await EmployeeApi.deleteEmployeeNote(token, timestamp);
        handleApiResponse(response, handler);  
    }

    public async deleteFlaggedItem(token:Nullable<string>, itemNumber:string, handler:ApiResponseHandler<any>):Promise<void> {

        if (!token || !itemNumber) {
            const error:ApiResponse = {data:null, error: REQUIRED_TOKEN_MESSAGE };
            handleApiResponse(error, handler);
            return;
        }

        const response = await EmployeeApi.deleteEmployeeFlaggedItem(token, itemNumber);
        handleApiResponse(response, handler);  
    }

    private validateNote(note:Note):Nullable<string> {
        if (!note) {
            return 'A note is required';
        }

        if (!note.noteText?.length && !note.customerIds?.length) {
          return 'A note or at least one customer is required';
        }

        return null;
    }
}

export default new EmployeeService();