import React from "react";
import { PresenterDownload } from "../../@types";

export interface PresenterDownloadProp {
    download: PresenterDownload
}

const PresenterDownloadLink:React.FC<PresenterDownloadProp> = ({download}) => {
    const {fileUrl, label} = download;

    return(
        <div className="presenter-sep d-md-inline-flex">
            <a href={fileUrl} target="_blank" rel="noreferrer">{label}</a>
        </div>
    )
};

export default PresenterDownloadLink;