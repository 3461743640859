import { ApiResponseHandler, AppThunk, Nullable, Presenter } from "../../../@types";
import PresenterService from "../../../service/app/presenter/presenterService";
import { LOAD_APP_NAME_SUCCESS } from "../appAction/appActionType";
import { FETCH_PRESENTER_LIST, FETCH_PRESENTER_LIST_SUCCESS, FETCH_PRESENTER_LIST_FAILURE } from "./presenterActionType";

export function loadPresenterList(token:Nullable<string>):AppThunk {
    return (dispatch) => {
        dispatch({
            type: FETCH_PRESENTER_LIST
        });

        if (!token) {
            dispatch({type: FETCH_PRESENTER_LIST_FAILURE});
        }
        else {
            const handler:ApiResponseHandler<Presenter[]> = {

                onSuccess : (presenters) => {
                    dispatch({
                        type: FETCH_PRESENTER_LIST_SUCCESS,
                        payload: presenters
                    });

                    const presenter = presenters.find(presenter => !!presenter.PK)
                    if (presenter) {
                        dispatch({
                            type: LOAD_APP_NAME_SUCCESS,
                            payload: presenter.PK
                        })
                    }
                },
                onError : (error:string) => {
                    dispatch({
                        type: FETCH_PRESENTER_LIST_FAILURE
                    });
                }
            }
            PresenterService.getPresenterList(token, handler);
        }
    }
}