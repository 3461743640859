import {AxiosInstance, AxiosResponse } from "axios";
import Axios from 'axios';
import { ApiResponse, Nullable } from "../@types";
import {getConfiguration} from '../config/configuration';
import { parseErrorMessage } from "../util/exceptionUtil";

const DEFAULT_HEADERS:any = {
    
};

const JSON_CONTENT_TYPE_HEADER = {
    'Content-Type' : 'application/json'
};

class Api {
    private createPublicAxiosInstance(isJsonData:boolean, token:Nullable<string>):AxiosInstance {
        const {publicBaseUrl} = getConfiguration();

        let headers = {...DEFAULT_HEADERS};
        if (isJsonData) {
            headers = {...headers, ...JSON_CONTENT_TYPE_HEADER};
        }

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return Axios.create({
            baseURL : publicBaseUrl,
            timeout : 30000,
            headers : headers,
            withCredentials :true
        });
    }

    private createB2BAxiosInstance(token:Nullable<string>):AxiosInstance {
        const {b2bBaseApiUrl} = getConfiguration();

        const headers = {...DEFAULT_HEADERS};
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return Axios.create({
            baseURL : b2bBaseApiUrl,
            timeout : 30000,
            headers : headers
        });
    }

    private createAWSAxiosInstance(token:string):AxiosInstance {
        const {awsBaseUrl} = getConfiguration();

        return Axios.create({
            baseURL : awsBaseUrl,
            timeout : 30000,
            headers : {
                ...DEFAULT_HEADERS,
                'Authorization' : `Bearer ${token}`
            }
        });
    }

    private parseApiResponse(response:AxiosResponse):ApiResponse {
        const responseData = response.data;
        if (responseData?.error) {
            return {
                data : null, 
                error: responseData.error
            };
        }
    
        return {
            data: responseData, 
            error: null
        };
    }

    public async performAwsGETRequest(url:string, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).get(url);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)}
        }
    }    

    public async performPOSTRequest(url:string, request:any, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).post(url, request);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)}
        }
    }
    
    public async performPUTRequest(url:string, request:any, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).put(url, request);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)}
        }
    }

    public async performDELETERequest(url:string, token:string):Promise<ApiResponse> {
        try {
            const response = await this.createAWSAxiosInstance(token).delete(url);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)}
        }
    }

    public async peformPublicPOSTRequest(url:string, request:any, token:Nullable<string>):Promise<ApiResponse> {
        try {
            const response = await this.createPublicAxiosInstance(true, token).post(url, request);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)}
        }
    }

    public async performB2BGETRequest(url:string, token:Nullable<string>):Promise<ApiResponse> {
        try {
            const response = await this.createB2BAxiosInstance(token).get(url);
            return this.parseApiResponse(response);
        }
        catch (error) {
            return {data: null, error: parseErrorMessage(error)};
        }
    }
}

export const REQUIRED_TOKEN_MESSAGE = 'An authentication token is required';
export default new Api();