import { MBAction, EmployeeState } from '../../@types';
import {
    INITIALIZE_EMPLOYEE,
    INITIALIZE_EMPLOYEE_SUCCESS,
    INITIALIZE_EMPLOYEE_FAILURE, 
    DELETE_NOTE,
    DELETE_NOTE_SUCCESS,
    DELETE_NOTE_FAILURE,
    ADD_NOTE,
    ADD_NOTE_SUCCESS,
    ADD_NOTE_FAILURE,
    UPDATE_NOTE,
    UPDATE_NOTE_SUCCESS,
    UPDATE_NOTE_FAILURE} from '../action/employee/employeeActionType';

const INITIAL_STATE:EmployeeState = {
    isLoading : false,
    username: "",
    flaggedItems : [],
    notes: []
};

function updateEmployeeState(state:EmployeeState = INITIAL_STATE, action:MBAction):EmployeeState {
    switch (action.type) {
        case INITIALIZE_EMPLOYEE :
            return {
                ...state, 
                isLoading: true
            };

        case INITIALIZE_EMPLOYEE_SUCCESS :
            return {
                ...state, 
                isLoading: false, 
                username: action.payload.username,
                flaggedItems: action.payload.flaggedItems,
                notes: action.payload.notes
            };

        case INITIALIZE_EMPLOYEE_FAILURE :
            return {
                ...state, 
                isLoading: false, 
                username: "",
                flaggedItems: [],
                notes: []
            };
        
        case ADD_NOTE :
            return {
                ...state,
                isLoading: true
            };

        case ADD_NOTE_SUCCESS :
            return {
                ...state,
                isLoading: false,
                notes: [...state.notes, action.payload]
            };

        case ADD_NOTE_FAILURE :
            return {
                ...state,
                isLoading: false
            };

        case UPDATE_NOTE :
            return {
                ...state,
                isLoading: true
            };

        case UPDATE_NOTE_SUCCESS :
            const notesUpdate = state.notes.map(note => {
                if (note.timestamp === action.payload.timestamp) {
                    return {...note, ...action.payload};
                }

                return note;
            });
            return {
                ...state,
                isLoading: false,
                notes: notesUpdate
            };

        case UPDATE_NOTE_FAILURE :
            return {
                ...state,
                isLoading: false
            };
            
        case DELETE_NOTE :
            return {
                ...state,
                isLoading: true
            };
            
        case DELETE_NOTE_SUCCESS :
            const updatedNotes = state.notes.filter(note => note.timestamp !== action.payload);
            return {
                ...state,
                isLoading: false,
                notes: updatedNotes
            };

        case DELETE_NOTE_FAILURE :
            return {
                ...state,
                isLoading: false
            };

        default : 
            return state;
    }
}

export default updateEmployeeState;