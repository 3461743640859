import React from "react";
import { Item, Note } from "../../@types";
import ItemNoteCard from "./itemNoteCard";
import { Accordion, Row, Col } from "react-bootstrap";
import { useAppSelector } from "../../hook/appHook";
import Image from "../app/image";

export interface NoteListProps {
    notes: Note[],
    itemNumber: string,
    presenterNumber: number
}

const PresenterItemNotes:React.FC<NoteListProps> = (props) => {
    const {notes, itemNumber} = props;
    const itemNotes = notes.filter(note => note.itemNumber === itemNumber);

    const {presenters} = useAppSelector((state) => state.PresenterState);
    const presenter = presenters.find((presenter) => presenter.number === props.presenterNumber);

    const item = presenter?.items.find((item:Item) => item.number === itemNumber);
    let description:string = '';
    let thumbnailUrl:string = '';
    if (item) {
        description = item.description;
        thumbnailUrl = item.thumbnailUrl;
    }

    return (
        <>
            <Accordion className="mb-2" alwaysOpen={false}>
                <Accordion.Item eventKey={itemNumber}>
                    <Accordion.Header as="div">
                        <Row className="m-0 align-items-center flex-grow-1">
                            <Col sm="auto">
                                <Image 
                                    src={thumbnailUrl} 
                                    alt={description} 
                                    className="img-fluid"
                                    width="50"
                                />
                            </Col>
                            <Col>
                                <div className="item-title">{description}</div>
                                <div className="mb-inline-label-group">
                                    <label>Number</label>
                                    <div>{itemNumber}</div>
                                </div>

                            </Col>
                            <Col sm="auto" className="ms-auto">
                                {itemNotes.length} {itemNotes.length > 1 ? 'Notes' : 'Note'} 
                            </Col>
                        </Row>
                    </Accordion.Header>
                    <Accordion.Body className="notes-list">
                        {itemNotes.map((note) =>
                            <ItemNoteCard key={note.timestamp} note={note} />
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
           
        </>
    )
}

export default PresenterItemNotes;