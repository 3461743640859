import React, { useEffect, useState } from "react";
import {Badge, Button, Card, Col, Row} from 'react-bootstrap';
import Image from '../app/image';
import NoteEditor, { NoteEditorProps } from "../note/noteEditor";
import { getConfiguration } from '../../config/configuration';
import { ItemListItem, Note } from "../../@types";
import { EMPTY_NOTE } from "../../service/app/employee/employeeService";
import ItemNoteCard from "../note/itemNoteCard";
import { useAppSelector } from "../../hook/appHook";
const {b2bServerUrl} = getConfiguration();

export type ItemProps = {
    item: ItemListItem,
};

const ItemComponent:React.FC<ItemProps> = (props) => {
    const item = props.item.item;
    const [isShowNoteEditor, setIsShowNoteEditor] = useState(false);
    const [areItemNotesShown, setAreItemNotesShown] = useState(false);
    const hasFlags = item.isSpecial || item.isVirtual;
    const specialFlagClass = `specialFlag${!!item.isVirtual ? ' mr-2' : ''}`;
    const itemDetailsUrl = `${b2bServerUrl}#!/itemdetails/${item.number}?authFlow=employee`;
    const { notes } = useAppSelector((state) => state.EmployeeState);
    const [itemNotes, setItemNotes] = useState<Note[]>([]);

    useEffect(() => {
        const filteredNotes = notes.filter(note => note.itemNumber === item.number);
        if (JSON.stringify(filteredNotes) !== JSON.stringify(itemNotes)) {
            setItemNotes(filteredNotes);
        }
    }, [item, notes, itemNotes]);

    const noteEditorModalProps:NoteEditorProps = {
        title: "Add Note",
        note: {
            ...EMPTY_NOTE,
            itemNumber: item.number,
            presenterNumber: props.item.presenterNumber,
            presenterName: props.item.presenterName
        },
        onModalClose: () => setIsShowNoteEditor(false)
    }

    const toggleItemNotes = () => {
        setAreItemNotesShown(!areItemNotesShown)
    }

    return (
        <Row className="g-0 border-0 border-bottom border-1">
            <Col>
                <Card className="border-0">
                    {hasFlags &&
                        <Row className="flag-container">
                            <Col className="text-end">
                                {item.isSpecial && 
                                    <Badge bg="" className={specialFlagClass}>Special Order</Badge>
                                }
                                {item.isVirtual &&
                                    <Badge bg="" className="virtualFlag">2-day</Badge>
                                }
                            </Col>
                        </Row>
                    }
                    <Card.Body className="px-0">
                        <Row className="m-0 align-items-center">
                            <Col sm="auto" className="mb-3 mb-md-0">
                                <Image 
                                    src={item.thumbnailUrl} 
                                    alt={item.description} 
                                    className="img-fluid" 
                                />
                            </Col>
                            <Col>
                                <Card.Subtitle className="fw-400 text-muted">
                                    {item.vendor && item.vendor}
                                </Card.Subtitle>
                                <Card.Title className="mb-1 fw-600">
                                    <a href={itemDetailsUrl} target="_blank" rel="noreferrer">
                                        {item.description}
                                    </a>
                                </Card.Title>
                                <Card.Text as="div" className="mb-0 mb-label-data-row">
                                    <div className="mb-inline-label-group">
                                        <label>Number</label>
                                        <div>{item.number}</div>
                                    </div>
                                    {item.packSize &&
                                        <div className="mb-inline-label-group">
                                            <label>Pack/Size</label>
                                            <div>{item.packSize}</div>
                                        </div>
                                    }
                                    {item.keyFeatures &&
                                        <div className="mb-inline-label-group">
                                            <label>Features</label>
                                            <div>{item.keyFeatures}</div>
                                        </div>
                                    }
                                </Card.Text>
                                <Card.Text>
                                    {item.marketingDescription}
                                </Card.Text>
                            </Col>
                            <Col className="pt-3 pt-md-0 text-sm-end d-grid d-sm-block" sm="auto">
                                <Button className="mb-button mb-2" variant="" size="sm" onClick={() => setIsShowNoteEditor(true)}>Note</Button>
                                {isShowNoteEditor && <NoteEditor {...noteEditorModalProps}  />}
                            </Col>
                        </Row>
                        {!!itemNotes.length &&
                            <div>
                                <div className="text-end"><Button variant="link" onClick={toggleItemNotes}>{areItemNotesShown ? 'Hide' : 'Show'} Items ({itemNotes.length})</Button></div>
                                {areItemNotesShown &&
                                <Row className="mt-3 mx-3 item-note-container border">
                                    <Col>
                                    {itemNotes.map((note) =>
                                        <ItemNoteCard key={note.timestamp} note={note} />
                                    )}
                                    </Col>
                                </Row>
                                }
                            </div>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ItemComponent;