import React from "react";
import { Presenter } from "../../@types";
import Image from "../app/image";
import { Link } from "react-router-dom";
import {Card, Col, Row} from 'react-bootstrap';

export type PresenterProps = {
    presenter: Presenter
};

const PresenterSummary:React.FC<PresenterProps> = ({presenter}) => {
    const {boothAssignment, number, name, logoUrl } = presenter;
    const presenterItems = presenter.items;

    return (
        <Col>
            <Link to={`/presenter-detail/${number}`} className="booth-card-link">
                <Card>
                    <Card.Header className="text-center">{name}</Card.Header>
                    <Card.Body className="py-1">
                        <Row className="align-items-center">
                            <Col sm="auto" xs={12} className="mb-2 mb-sm-0 presenter-logo-container">
                            <Image 
                                src={logoUrl} 
                                className="vendor-logo" 
                                alt={name} 
                                width="80"
                                />
                            </Col>
                            <Col className="text-sm-start text-center pb-2 pb-sm-0">
                                <Card.Title><span className="fw-500">Booth:</span> {boothAssignment}</Card.Title>
                                <Card.Title className="mb-0"><span className="fw-500">Items:</span> {presenterItems.length}</Card.Title>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    )
};

export default PresenterSummary;