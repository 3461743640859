import { ApiTokenResponse, Nullable } from "../../@types";

const STORAGE = window.localStorage;

const USER_TOKENS_KEY = 'userTokens';

class UserDao {
    private prepareJsonData(data:Object|Array<any>):Nullable<string> {
        if (data == null) {
            return null;
        }

        return JSON.stringify(data);
    }

    private parseJsonData(dataString:Nullable<string>):Nullable<Object|Array<any>> {
        if (!dataString) {
            return null;
        }

        let jsonData = null;
        try {
            jsonData = JSON.parse(dataString);
        }
        catch (error) {
            //Logger.warn(`Failed to parse UserSession data. ${error}. Default data will be used`, dataString);
        }

        return ((jsonData != null) && (Object.keys(jsonData).length || Array.isArray(jsonData)))
            ? jsonData
            : null;
    }

    private retrieveData(key:string):Nullable<Object|Array<any>> {
        return this.parseJsonData(STORAGE.getItem(key));
    }

    private persistData(key:string, value:Object|Array<any>):void {
        const jsonString = this.prepareJsonData(value);

        if (jsonString) {
            STORAGE.setItem(key, jsonString);
        }
    }

    public removeAllData() {
        STORAGE.clear();
    }

    private removeData(key:string) {
        STORAGE.removeItem(USER_TOKENS_KEY);
    }

    public retrieveLastUsedTokens():Nullable<ApiTokenResponse> {
        return this.retrieveData(USER_TOKENS_KEY) as ApiTokenResponse;
    }

    public persistUserTokens(tokens:ApiTokenResponse):void {
        this.persistData(USER_TOKENS_KEY, tokens);
    }

    public removeUserTokens():void {
        this.removeData(USER_TOKENS_KEY);
    }
}

export default new UserDao();