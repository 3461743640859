import { ApiResponse, Note } from "../../@types";
import Api from "../api";

const EMPLOYEE_BASE = 'employee';

class EmployeeApi {
    public async initializeEmployee(token:string):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${EMPLOYEE_BASE}/initialize-data`, {}, token);
        return response;
    }

    public async createEmployeeNote(token:string, note:Note):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${EMPLOYEE_BASE}/notes/create`, note, token);
        return response;
    }

    public async createEmployeeFlaggedItem(token:string, itemNumber:string):Promise<ApiResponse> {
        const response = await Api.performPOSTRequest(`${EMPLOYEE_BASE}/flagged-item/create`, {itemNumber}, token);
        return response;
    }

    public async updateEmployeeNote(token:string, note:Note):Promise<ApiResponse> {
        const response = await Api.performPUTRequest(`${EMPLOYEE_BASE}/notes/update`, note, token);
        return response;
    }

    public async deleteEmployeeNote(token:string, timestamp:string):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${EMPLOYEE_BASE}/notes/delete/${timestamp}`, token);
        return response;
    }

    public async deleteEmployeeFlaggedItem(token:string, itemNumber:string):Promise<ApiResponse> {
        const response = await Api.performDELETERequest(`${EMPLOYEE_BASE}/flagged-item/delete/${itemNumber}`, token);
        return response;
    }
}

export default new EmployeeApi();