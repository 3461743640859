import React, { useEffect } from 'react';
import { Nullable } from '../@types';
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { initializeApp } from '../state/action/appAction/appAction';
import Home from './home';
import Login from './login';

const CODE_PARAMETER = 'code=';

const Main:React.FC<any> = () => {
    const {appUser, isLoading, isInitialized} = useAppSelector((state) => state.AppState);
    const dispatch = useAppDispatch();
    
    let code:Nullable<string> = null;
    const url = window.location.href;
    const codeStart = url.indexOf(CODE_PARAMETER);

    useEffect(() => {
        if (!isLoading && !isInitialized) {
            dispatch(initializeApp(code));
        }
    }, [dispatch, code, isLoading, isInitialized]);

    if (codeStart >= 0) {
        code = url.substring(codeStart + CODE_PARAMETER.length);
    }

    if (appUser) {
        return <Home />
    }

    return <Login code={code} />
};

export default Main;