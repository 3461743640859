import React, {useState} from "react";
import { ApiResponseHandler, Note, Nullable } from "../../@types";
import { Alert, Form } from "react-bootstrap";
import MBModal, { MBModalProps } from "../modal/mbModal";
import { useAccessToken } from "../../hook/accessTokenHook";
import CustomerSelector from "../customer/customerSelector";
import { Badge, CloseButton } from "react-bootstrap";
import { saveNote } from "../../state/action/employee/employeeAction";
import { useAppDispatch } from "../../hook/appHook";

export type NoteEditorProps = {
    title?: string,
    note: Note,
    onModalClose: () => void
};

const NoteEditor:React.FC<NoteEditorProps> = (props) => {
    const [note, setNote] = useState(props.note);
    const [error, setError] = useState<Nullable<string>>(null);
    const token = useAccessToken();
    const dispatch = useAppDispatch();

    const onNoteSave = ():Promise<boolean> => {
        return new Promise(resolve => {
            const handler:ApiResponseHandler<Note> = {
                onSuccess: (data) => {
                    resolve(true);
                },
                onError: (apiError) => {
                    setError(apiError);
                    resolve(false);
                },
                onComplete: () => {}
            }

            dispatch(saveNote(token, note, handler));
        })
    }

    const noteTextChanged = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        const noteText = event.currentTarget.value;
        const updateNote = {
            ...note,
            noteText: noteText
        }

        setNote(updateNote);
    };

    const noteModalProps:MBModalProps = {
        mbModalProps: {
            title: props.title ? props.title : "Edit Note",
            size: "lg",
            actionBtnText: props.note.timestamp ? "Edit" : "Add",
            onModalSubmit: () => onNoteSave()
        },
        show: true,
        onHide: () => {
            props.onModalClose();
            return Promise.resolve(true)
        }
    }

    const customerSelected = (customerId:number) => {
        let customerIds:number[] = [];
        if (note.customerIds?.length) {
            customerIds = [...note.customerIds];
        }

        customerIds.push(customerId);
        setNote({...note, customerIds});
    }

    const removeSelectedCustomer = (customerId:number) => {
        let customerIds:number[] = [];
        if (note.customerIds?.length) {
            customerIds = [...note.customerIds];
        }

        const remainingCustomerIds = customerIds.filter(noteCustomerId => customerId !== noteCustomerId);
        setNote({...note, customerIds: remainingCustomerIds});
    }

    return(
        <MBModal {...noteModalProps}>
            {!!error &&
                <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>
            }
            
            <div className="mb-label-data-row mb-3">
                <div className="mb-inline-label-group">
                    <label>Presenter</label>
                    <div>{note.presenterNumber}</div>
                </div>
                <div className="mb-inline-label-group">
                    <label>Presenter Name</label>
                    <div>{note.presenterName}</div>
                </div>
                {note.itemNumber &&
                    <div className="mb-inline-label-group">
                        <label>Number</label>
                        <div>{note.itemNumber}</div>
                    </div>
                }
            </div>
            <Form.Group className="mb-2" controlId="note.ControlTextarea1">
                <Form.Label className="fw-600">Note</Form.Label>
                <Form.Control as="textarea" rows={3} value={note.noteText} onChange={noteTextChanged} />
            </Form.Group>

            {!!note?.customerIds?.length &&
                <div>
                    {note.customerIds.map((customerId) =>
                        <Badge key={customerId} bg="" text="dark" className="customer-badge me-2" onClick={() => removeSelectedCustomer(customerId)}>
                            <span className="me-2">{customerId}</span>
                            <CloseButton />
                        </Badge>
                    )}
                </div>
            }          
            <div className="my-3">
                <CustomerSelector selectedCustomers={note.customerIds ?? []} onCustomerSelected={customerSelected} />
            </div>

        </MBModal>
    )
}

export default NoteEditor;