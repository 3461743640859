import React from "react";
import { Note } from "../../@types";
import { Accordion, Row, Col } from "react-bootstrap";
import { useAppSelector } from "../../hook/appHook";
import Image from "../app/image";
import PresenterItemNotes from "./presenterItemNotes";
import PresenterOnlyNotes from "./presenterOnlyNotes";
import PresenterContacts from "../presenter/presenterContacts";

export interface PresenterNotesProp {
    notes: Note[],
    presenterNumber: number
}

const PresenterNotes:React.FC<PresenterNotesProp> = (props) => {
    const {presenters} = useAppSelector((state) => state.PresenterState);
    const {presenterNumber, notes} = props;
    const matchedPresenter = presenters.find(presenter => presenter.number === presenterNumber);

    const presenterNotes = notes.filter((note) => note.presenterNumber === presenterNumber);

    const presenterOnlyNotes = presenterNotes.filter((note) => !note.itemNumber);
    const itemNumbers:string[] = [];
    const itemNotes = presenterNotes.filter((note) => !!note.itemNumber);

    itemNotes.forEach((note) => {
        if (!itemNumbers.includes(note?.itemNumber ?? '')) {
            itemNumbers.push(note?.itemNumber ?? '')
        }       
    });

    const {presenterName} = presenterNotes[0];
    return (
        <Accordion.Item eventKey={props.presenterNumber.toString()} className="border mb-2">
            <Accordion.Header as="div">
                <Row className="m-0 align-items-center flex-grow-1">
                    <Col sm="auto">
                        <Image 
                            src={matchedPresenter?.logoUrl} 
                            alt={presenterName} 
                            className="img-fluid"
                            width="80"
                        />
                    </Col>
                    <Col>
                        {presenterName}
                        {!!matchedPresenter?.contacts.length && <PresenterContacts contacts={matchedPresenter.contacts} />}
                    </Col>
                    <Col sm="auto" className="ms-auto">
                        {presenterNotes.length} Notes
                    </Col>
                </Row>
            </Accordion.Header>
            <Accordion.Body>
                {!!presenterOnlyNotes.length && <PresenterOnlyNotes presenterNumber={presenterNumber} notes={presenterOnlyNotes} />}
                {itemNumbers.map((itemNumber) =>
                    <PresenterItemNotes key={itemNumber} presenterNumber={presenterNumber} itemNumber={itemNumber} notes={itemNotes} />
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default PresenterNotes;