import React from "react";
import PresenterDownloadLink from "./presenterDownload";
import { PresenterDownload } from "../../@types";

export interface PresenterDownloadsProps {
    downloads: PresenterDownload[]
}

const PresenterDownloads:React.FC<PresenterDownloadsProps> = ({downloads}) => {

    const downloadComponents = downloads.map((download) => <PresenterDownloadLink key={download.fileUrl} download={download} /> );
    return (
        <>
            {!!downloadComponents.length && 
                <div className="mb-inline-label-group align-items-start">
                    <label>Downloads</label>
                    <div>
                        {downloadComponents}
                    </div>
                </div>
            }
        </>
    )
}

export default PresenterDownloads;