import { ApiResponse, ApiResponseHandler } from "../../@types";
import { handleApiResponse } from "../../util/apiUtil";
import AppApi from '../../api/app/appApi';

class AppService {
    public async exchangeCodeForTokens(code:string, handler:ApiResponseHandler<any>):Promise<void> {
        if (!code) {
            const error:ApiResponse = {data: null, error: 'A login code is required'};
            handleApiResponse(error, handler);
            return;
        }

        const response = await AppApi.exchangeCodeForTokens(code);
        handleApiResponse(response, handler);
    }
}

export default new AppService();