import React from "react";
import { ItemListItem } from "../../@types";
import ItemComponent from "./item";

export interface ItemListProps {
    items: ItemListItem[]
}

const ItemList:React.FC<ItemListProps> = (props) => {

    return (
        <div>
            {props.items.map((item) => <ItemComponent key={item.item.number} item={item} />)}
        </div>
    )
}

export default ItemList;