import React, { useState } from "react"
import {Button, Modal, ModalProps} from 'react-bootstrap';
import Loader from "../app/loader";

export interface MBModalProps extends ModalProps {
    mbModalProps: {
        title: string,
        actionBtnText?: string,
        closeBtnText?: string,
        size: "sm" | "lg" | "xl",
        onModalSubmit: () => Promise<boolean>
    }
}

const MBModal:React.FC<MBModalProps> = (props) => {
    const [isModalLoading, setModalIsLoading] = useState(false);
    const submitModal = () => {
        setModalIsLoading(true);
        props.mbModalProps.onModalSubmit().then(isSuccess => {
            if (isSuccess) {
                props.onHide?.();
            }
        }).finally(() => setModalIsLoading(false))
    };

    const hideModal = () => {
        setModalIsLoading(false);
        props.onHide?.();
    }
    
    const bootstrapProps:Partial<MBModalProps> = {...props};
    bootstrapProps.size = props.mbModalProps.size;
    delete bootstrapProps.mbModalProps;
    
    return (
    
        <Modal
            {...bootstrapProps}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.mbModalProps.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isModalLoading && <Loader className="loader-top" />}
                {props.children}
                </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={submitModal}>{props.mbModalProps.actionBtnText ?? 'Add'}</Button>
                <Button size="sm" variant="" className="mb-button" onClick={hideModal}>{props.mbModalProps.closeBtnText ?? 'Cancel'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MBModal;