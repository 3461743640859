import { ApiResponse } from "../../@types";
import Api from '../api';
import { getConfiguration } from '../../config/configuration';

class AppApi {
    public async exchangeCodeForTokens(code:string):Promise<ApiResponse> {
        const {clientId} = getConfiguration();
        const url = `users/tokens/${clientId}/${code}`;
        const response = await Api.peformPublicPOSTRequest(url, null, null);
        return response;
    }
}

export default new AppApi();

