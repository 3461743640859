import { ApiResponse } from "../../@types";
import Api from "../api";

class PresenterApi {
    public async fetchPresenterList(token:string):Promise<ApiResponse> {
        const response = await Api.performAwsGETRequest('presenters', token);
        return response;
    }
}

export default new PresenterApi();