import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { Nullable } from "../@types";
import { getConfiguration } from "../config/configuration";
import { createCognitoUserFromAppUser } from "../state/action/appAction/appAction";
import { ACCESS_TOKEN_REFRESH } from "../state/action/appAction/appActionType";
import { useAppDispatch, useAppSelector } from "./appHook";

const MIN_TOKEN_REFRESH_MINUTES = 15;

export function useAccessToken():Nullable<string> {
    const [accessToken, setAccessToken] = useState<string|null>(null);

    const {appUser} = useAppSelector((state) => state.AppState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!appUser) {
            return;
        }

        const userAccessToken = appUser.tokens.accessToken;
        const expiration = userAccessToken.getExpiration();
        const currentTime = new Date().getTime() / 1000;
        const minutesUntilExpired = (expiration - currentTime) / 60;

        if (minutesUntilExpired > MIN_TOKEN_REFRESH_MINUTES) {
            setAccessToken(userAccessToken.getJwtToken());
            return;
        }

        const {userPoolId, clientId} = getConfiguration();
        const cognitoUser = createCognitoUserFromAppUser(appUser, userPoolId, clientId);
        if (!cognitoUser) {
            return;
        }

        cognitoUser.getSession((error:Nullable<Error>, session:Nullable<CognitoUserSession>) => {
            if (error || !session) {
                setAccessToken(null);
                return;
            }
    
            const cognitoRefreshToken = session.getRefreshToken();
            
    
            const refreshHandler = (error:Nullable<Error>, refreshSession:CognitoUserSession) => {
                if (error || !refreshSession) {
                    setAccessToken(null);
                    return;
                }
    
                const newAccessToken = refreshSession.getAccessToken();

                setAccessToken(newAccessToken.getJwtToken());

                dispatch({
                    type: ACCESS_TOKEN_REFRESH,
                    payload: newAccessToken
                })
            };
    
            cognitoUser.refreshSession(cognitoRefreshToken, refreshHandler);
        });
    }, [appUser, dispatch]);
    
    return accessToken;
}