import { ApiResponseHandler, Nullable } from "../../@types";
import { handleApiResponse } from "../../util/apiUtil";
import B2BCustomerApi from "../../api/user/b2bUserApi";

class B2BUserService {
    public async getUserCustomers(token:Nullable<string>, handler:ApiResponseHandler<any>):Promise<void> {
        if (!token) {
            handleApiResponse({data: null, error: 'A validation token is required'}, handler);
            return;
        }

        const response = await B2BCustomerApi.fetchUserCustomers(token);
        handleApiResponse(response, handler);
    }
}

export default new B2BUserService();