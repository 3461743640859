import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { AppConfig, Nullable } from '../@types';
import Image from '../component/app/image';
import Loader from '../component/app/loader';
import { constructEmployeeLoginUrl, getConfiguration } from '../config/configuration';
import { useAppSelector } from '../hook/appHook';

export interface LoginProps {
    code:Nullable<string>
}

const Login:React.FC<LoginProps> = ({code}) => {
    const config:AppConfig = getConfiguration();
    const loginUrl = constructEmployeeLoginUrl(config);

    const {
        isLoading,
        isInitialized, 
        isLoginFailed} = useAppSelector((state) => state.AppState);

    if (!isLoading && !isLoginFailed && isInitialized) {
        window.location.href = loginUrl;
        return (<div></div>);
    }
    
    return (
        <div style={{backgroundColor: 'black', height: '100vh'}}>
            {isLoading && <Loader />}

            <Container className="h-100">

                <Row className="text-center d-flex align-items-center h-100">
                    <Col>
                        <Image 
                            src="https://cdn.martinbros.com/content/m_badge_logo_300.png" 
                            alt="MB Logo" />
                        <div className="mt-4">
                        {isLoading && !!code && 
                            <div className="my-3 text-white">Please wait while we gather your data</div>
                        }
                        {!isLoading &&
                            <Button variant="outline-light" onClick={() => window.location.href = loginUrl}>
                                Please Login
                            </Button>
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;