import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { logout } from '../state/action/appAction/appAction';

const Logout:React.FC<any> = (props) => {

    const {isLoading, appUser} = useAppSelector((state) => state.AppState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);
    
    if (isLoading) {
        return (
            <div>Please wait as we end your session</div>
        )
    }

    if (appUser) {
        return (<div>We were unable to end your session</div>);
    }

    return (
        <div>
            <div>Your session has been successfully terminated</div>
            <Link to="/">Login</Link>
        </div>    
    );
};

export default Logout;